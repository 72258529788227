// TableFooter.js
import React from 'react';

const TableFooter = ({ columns }) => {
  return (
    <tr className="table-footer-row">
      <td colSpan={columns.length}>
        <div className="table-footer-dual-container">
          {/* Primer cuadro de información */}
          <div className="table-footer-box">
            <div className="table-footer-content">
              <h3 className="footer-title">Información Importante</h3>
              <p>- Decants de perfumes 100% originales</p>
              <p>
                - Envíos a todo Chile mediante Starken por pagar (o
                cualquier otra compañia de tu preferencia)
              </p>
              <p>- Tiempo de preparación: 24-48 horas hábiles</p>
              <p>- Consultas disponibles vía Instagram</p>
              <p>- Emitimos boleta electronica por cada compra</p>
            </div>
          </div>

          {/* Segundo cuadro de información */}
          <div className="table-footer-box">
            <div className="table-footer-content">
              <h3 className="footer-title">Datos de transferencia</h3>
              <p>MEGAMI SpA</p>
              <p>77.341.464-5</p>
              <p>Banco de Chile</p>
              <p>Cuenta FAN Emprende (Vista)</p>
              <p>00-005-10242-77</p>
              <p>perfumescasavalentine@gmail.com</p>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default TableFooter;
