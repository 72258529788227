import React, { useState } from 'react';
import axios from 'axios';
import config from '../config.js';  // Importamos la configuración

const ExcelUploader = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setMessage('Por favor selecciona un archivo');
      return;
    }

    const formData = new FormData();
    formData.append('excelFile', file);

    try {
      const token = localStorage.getItem('token'); // Asume que guardas el token en localStorage después del login
      const response = await axios.post(`${config.API_URL}/api/upload-excel`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}` // Incluye el token en el header
        }
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error al subir el archivo: ' + (error.response?.data?.message || error.message));
    }
  };

  return (
    <div>
      <h2>Subir archivo Excel</h2>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} accept=".xlsx, .xls" />
        <button type="submit">Subir</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ExcelUploader;
