// MiniFooter.js
import React from 'react';

const MiniFooter = () => {
  return (
    <div>
      <div className="miniFooter">
        <a
          href="https://www.instagram.com/casavalentine.cl"
          className="miniFooter-Links"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="bi bi-instagram"></i>
          <p className="miniFooter-Texto">Instagram</p>
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61565349879054&sk=about"
          className="miniFooter-Links"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="bi bi-facebook"></i>
          <p className="miniFooter-Texto">Facebook</p>
        </a>
        <a
          href="https://www.tiktok.com/@casavalentine.cl"
          className="miniFooter-Links"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="bi bi-tiktok"></i>
          <p className="miniFooter-Texto">TikTok</p>
        </a>
      </div>
      <div className="miniFooter-desarrollador">
        <p>
          Desarrollado y Diseñado por{" "}
          <a
            href="https://www.tiol.cl"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tiol
          </a>{" "}
          2024.
        </p>
      </div>
    </div>
  );
};

export default MiniFooter;
