import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import CartModal from "./CartModal";
import TableFooter from './TableFooter';  
import MiniFooter from './MiniFooter'; 

// Imágenes
import CloseIcon from "../assets/boton-cerrar.png";
import AddCartIcon from "../assets/boton-agregarcarrito.png";

// Hooks personalizados
import useDecantsData from '../hooks/useDecantsData';
import useCart from '../hooks/useCart';

// Utilidades
import { formatCurrency, getCartIconUrl } from '../utils/formatters';
import { COLUMN_TYPES, URL_WEBASSETS, BASE_FONT_SIZE, MIN_FONT_SIZE, MAX_FONT_SIZE } from '../utils/constants';

// Configuración de Modal
Modal.setAppElement("#root");

const VitrinaDecants = () => {
  // Hooks personalizados para datos y carrito
  const {
    data,
    filteredData,
    loading,
    error,
    filters,
    showFilters,
    sortConfig,
    columnMapping,
    columns,
    handleFilterChange,
    clearFilter,
    clearAllFilters,
    requestSort
  } = useDecantsData();

  const {
    cart,
    cartModalIsOpen,
    handleAddToCart,
    removeFromCart,
    openCartModal,
    closeCartModal
  } = useCart();

  // Estados locales
  const [fontSize, setFontSize] = useState(BASE_FONT_SIZE);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  
  // Referencias
  const filterInputRefs = useRef({});

  // Efecto para ajustar la altura dinámica
  useEffect(() => {
    const setDynamicVH = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setDynamicVH();
    window.addEventListener("resize", setDynamicVH);
    return () => window.removeEventListener("resize", setDynamicVH);
  }, []);

  // Funciones para el modal de imagen
  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  // Función para ajustar el tamaño de fuente
  const handleFontSizeChange = (increment) => {
    setFontSize((prevSize) => Math.max(MIN_FONT_SIZE, Math.min(MAX_FONT_SIZE, prevSize + increment)));
  };

  // Función para limpiar filtros y referencias
  const handleClearFilter = (column) => {
    clearFilter(column);
    if (filterInputRefs.current[column]) {
      filterInputRefs.current[column].value = "";
    }
  };

  const handleClearAllFilters = () => {
    clearAllFilters();
    Object.values(filterInputRefs.current).forEach((input) => {
      if (input) input.value = "";
    });
  };

  // Renderizado de columna de precios
  const renderPriceColumn = (row, originalColumn) => {
    const realValueColumn = `Real_${originalColumn}`;
    const hasDiscount = row[realValueColumn] !== row[originalColumn];
    
    return (
      <div className="price-container">
        {row[originalColumn] != null ? (
          <>
            <div className="price-stack">
              {hasDiscount && (
                <span className="real-price">
                  {formatCurrency(row[realValueColumn])}
                </span>
              )}
              <span className="final-price">
                {formatCurrency(row[originalColumn])}
              </span>
            </div>
            <button
              className="add-toCart-button"
              onClick={(event) => {
                handleAddToCart(event, {
                  id: row.Id,
                  marca: row.Marca,
                  nombre: row.Nombre,
                  conc: row.Conc,
                  imagen: row.Imagen,
                  titulo: originalColumn.split("_")[1],
                  valor: row[originalColumn],
                });
              }}
            >
              <img
                src={AddCartIcon}
                alt="Agregar Carrito"
                className="add-cart-icon"
              />
            </button>
          </>
        ) : (
          <span>No disponible</span>
        )}
      </div>
    );
  };
// Renderizado condicional para estados de carga y error
  if (loading) return (
    <div className="preloader" id="preloader">
      <h4>Cargando...</h4>
    </div>
  );
  if (error) return <div>{error}</div>;
  if (data.length === 0) return <div>No hay datos disponibles</div>;

  return (
    <div className="data-table-container" style={{ height: "calc(var(--vh, 1vh) * 100 - 5px)" }}>
      {/* Header con logo y carrito */}
      <div className="table-header">
        <div className="table-title">
          <img src={`${URL_WEBASSETS}img/casavalentine_logo.png`} alt="CasaValentine Logo" className="logo" />
          <p className="marca">CASAVALENTINE</p>
          <p className="guion">-</p>
          <p className="producto">Decants</p>
        </div>
        <div className="table-controls">
          <button className="cart-button" onClick={openCartModal}>
            <p className="cart-buttonText">Ver Carrito ({cart.length}) </p>
            <img 
              src={getCartIconUrl(cart.length, URL_WEBASSETS)} 
              alt={`Carrito ${cart.length}`} 
              className="cart-icon-image-cambiante" 
            />
          </button>
        </div>
      </div>

      {/* Sección de filtros activos */}
      {showFilters && (
        <div className="div-filtros">
          <div className="active-filters">
            <button onClick={handleClearAllFilters}>Borrar filtros</button>
            <p>Filtros activos:</p>
            {Object.entries(filters).map(([key, value]) => (
              <span key={key} className="filter-tag">
                {key}: {value}
                <button onClick={() => handleClearFilter(key)}>×</button>
              </span>
            ))}
          </div>
        </div>
      )}

      {/* Controles de tamaño de fuente */}
      <div className="font-size-controls">
        <button onClick={() => handleFontSizeChange(-1)}>-</button>
        <button onClick={() => handleFontSizeChange(1)}>+</button>
      </div>

      {/* Tabla principal */}
      <div className="table-wrapper">
        <table style={{ fontSize: `${fontSize}px` }}>
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column}
                  className={`
                    ${COLUMN_TYPES.HIGHLIGHT.includes(column.toLowerCase()) ? "highlight-column" : ""}
                    ${COLUMN_TYPES.ID.includes(column.toLowerCase()) ? "id-column" : ""}
                    ${COLUMN_TYPES.DESCUENTO.includes(column.toLowerCase()) ? "descuento-column" : ""}`}
                >
                  <div className="column-header">
                    <span onClick={() => requestSort(column)}>
                      {column}
                      {COLUMN_TYPES.FRASCO_ESPECIAL.includes(column.toLowerCase()) && (
                        <div className="floating-text">Frasco especial</div>
                      )}
                    </span>
                    <span className="sort-indicator">
                      {sortConfig.key === column && (sortConfig.direction === "ascending" ? "▲" : "▼")}
                    </span>
                  </div>
                  <div className="filter-container">
                    <input
                      ref={(el) => (filterInputRefs.current[column] = el)}
                      type="text"
                      className="filter-input"
                      placeholder={`Filtrar ${column}`}
                      onChange={(e) => handleFilterChange(column, e.target.value)}
                      value={filters[column] || ""}
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column) => {
                  const originalColumn = columnMapping[column];
                  return (
                    <td
                      key={column}
                      className={`
                        ${COLUMN_TYPES.HIGHLIGHT.includes(column.toLowerCase()) ? "highlight-column" : ""}
                        ${COLUMN_TYPES.ID.includes(column.toLowerCase()) ? "id-column" : ""}
                        ${COLUMN_TYPES.DESCUENTO.includes(column.toLowerCase()) ? "descuento-column" : ""}`}
                    >
                      {COLUMN_TYPES.IMAGE.includes(column.toLowerCase()) ? (
                        <img
                          src={row[originalColumn]}
                          alt="Perfume"
                          className="image-cell"
                          onClick={() => openModal(row[originalColumn])}
                          style={{ cursor: "pointer" }}
                        />
                      ) : column.toLowerCase() === "descuento" ? (
                        <div className="discount-cell-container">
                          <div className={row[originalColumn] ? "discount-tag" : ""}>
                            {row[originalColumn] && (
                              <>
                                <span className="discount-percent">{row[originalColumn]}%</span>
                                <span className="discount-text">Desc</span>
                              </>
                            )}
                          </div>
                        </div>
                      ) : column.startsWith("Valor") ? (
                        renderPriceColumn(row, originalColumn)
                      ) : (
                        <span>{row[originalColumn]}</span>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
            {/* Nueva fila de pie de tabla con dos cuadros */}
            <TableFooter columns={columns} />
          </tbody>
        </table>
      </div>

      {/* Modal de imagen */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="customModalContent"
        overlayClassName="customModalOverlay"
      >
        <button className="close-button" onClick={closeModal}>
          <img src={CloseIcon} alt="Cerrar" />
        </button>
        {selectedImage && <img src={selectedImage} alt="Perfume grande" />}
      </Modal>

      {/* Modal de carrito */}
      <CartModal
        isOpen={cartModalIsOpen}
        closeCartModal={closeCartModal}
        cart={cart}
        removeFromCart={removeFromCart}
      />

      {/* Footer */}
      <MiniFooter />
    </div>
  );
};

export default VitrinaDecants;
