/**
 * Hook personalizado para manejar la lógica del carrito de compras
 * @module hooks/useCart
 */

import { useState } from 'react';
import { flyToCart } from '../utils/animations';

/**
 * Hook para gestionar el estado y funcionalidad del carrito de compras
 * @returns {Object} Estado y funciones para manejar el carrito
 */
const useCart = () => {
  // Estados para el carrito y su modal
  const [cart, setCart] = useState([]);
  const [cartModalIsOpen, setCartModalIsOpen] = useState(false);

  /**
   * Añade un producto al carrito
   * @param {Object} item - Producto a agregar al carrito
   * @param {number} item.id - ID del producto
   * @param {string} item.marca - Marca del producto
   * @param {string} item.nombre - Nombre del producto
   * @param {string} item.conc - Concentración del producto
   * @param {string} item.imagen - URL de la imagen del producto
   * @param {string} item.titulo - Título/tamaño del producto
   * @param {number} item.valor - Valor del producto
   */
  const addToCart = (item) => {
    const newItem = {
      id: item.id,
      marca: item.marca,
      nombre: item.nombre,
      conc: item.conc,
      imagen: item.imagen,
      titulo: item.titulo,
      valor: item.valor,
    };
    setCart((prevCart) => [...prevCart, newItem]);
  };

  /**
   * Elimina un producto del carrito por su índice
   * @param {number} index - Índice del producto a eliminar
   */
  const removeFromCart = (index) => {
    setCart(prevCart => prevCart.filter((_, i) => i !== index));
  };

  /**
   * Abre el modal del carrito
   */
  const openCartModal = () => setCartModalIsOpen(true);

  /**
   * Cierra el modal del carrito
   */
  const closeCartModal = () => setCartModalIsOpen(false);

  /**
   * Agrega un producto al carrito con animación
   * @param {Event} event - Evento del click
   * @param {Object} item - Producto a agregar
   */
  const handleAddToCart = (event, item) => {
    flyToCart(event);
    addToCart(item);
  };

  /**
   * Obtiene el conteo total de items en el carrito
   * @returns {number} Cantidad total de items
   */
  const getCartItemCount = () => cart.length;

  return {
    // Estados
    cart,
    cartModalIsOpen,
    
    // Funciones principales
    addToCart,
    removeFromCart,
    openCartModal,
    closeCartModal,
    handleAddToCart,
    
    // Utilidades
    getCartItemCount
  };
};

export default useCart;