/**
 * Utilidades para formato y manipulación de datos
 */

/**
 * Formatea un valor numérico a formato de moneda chilena
 * @param {number} value - Valor a formatear
 * @returns {string} Valor formateado en formato de moneda
 */
export const formatCurrency = (value) => {
    if (value == null || isNaN(value)) return "No Disponible";
    return `$${parseInt(value).toLocaleString("es-CL")}`;
  };
  
  /**
   * Verifica si un valor es numérico
   * @param {any} value - Valor a verificar
   * @returns {boolean}
   */
  export const isNumeric = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };
  
  /**
   * Calcula el índice del ícono del carrito basado en la cantidad de items
   * @param {number} itemCount - Cantidad de items en el carrito
   * @returns {string} URL del ícono correspondiente
   */
  export const getCartIconUrl = (itemCount, urlWebassets) => {
    const iconIndex = Math.min(itemCount, 18);
    return `${urlWebassets}img/carita/${iconIndex}.svg`;
  };