/**
 * Constantes utilizadas en la aplicación
 */

export const COLUMN_TYPES = {
    NUMERIC: ["precio", "valor 3ml", "valor 5ml", "valor 11ml"],
    CURRENCY: ["precio", "valor 3ml", "valor 5ml", "valor 11ml"],
    HIGHLIGHT: ["precio", "nombre", "marca"],
    IMAGE: ["imagen", "vit archivo imagen"],
    ID: ["id", "identificador"],
    FRASCO_ESPECIAL: ["valor 11ml"],
    DESCUENTO: ["descuento"]
  };
  
  export const BASE_FONT_SIZE = 14;
  export const MIN_FONT_SIZE = 10;
  export const MAX_FONT_SIZE = 20;
  
  export const ANIMATION_DURATION = 800; // ms
  
  export const URL_WEBASSETS = "https://www.casavalentine.cl/assets/";