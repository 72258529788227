import config from "../config.js";

const API = {
  /**
   * Obtiene los datos de los decants desde el backend
   * @returns {Promise} Promesa que resuelve con los datos de los decants
   * @throws {Error} Error si la petición falla
   */
  fetchDecants: async () => {
    try {
      const response = await fetch(`${config.API_URL}/api/vitrinadecants`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(`Error al cargar los datos: ${error.message}`);
    }
  }
};

export default API;