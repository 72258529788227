import { useState, useEffect, useMemo } from 'react';
import API from '../services/api';
import { isNumeric } from '../utils/formatters';
import { COLUMN_TYPES } from '../utils/constants';

/**
 * Hook personalizado para manejar la lógica de datos y filtrado de la vitrina de decants
 * @returns {Object} Estado y funciones para manejar los datos y filtros
 */
const useDecantsData = () => {
  // Estados principales para los datos
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Estados para filtros y ordenamiento
  const [filters, setFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  // Efecto para cargar datos iniciales
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await API.fetchDecants();
        setData(fetchedData);
        setFilteredData(fetchedData);
        setLoading(false);
      } catch (e) {
        setError(e.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Mapeo de columnas para la tabla
  const columnMapping = useMemo(() => {
    if (data.length === 0) return {};
    
    // Verificar si existe al menos un registro con descuento
    const hasDiscounts = data.some(item => item.Descuento != null && item.Descuento !== 0);
    
    return Object.keys(data[0]).reduce((acc, key) => {
      // Excluir las columnas Real_Valor
      if (!key.startsWith('Real_Valor')) {
        // Solo incluir la columna descuento si hay descuentos
        if (key === "Descuento") {
          if (hasDiscounts) {
            acc[key] = key;
          }
        } else if (key === "tipo") {
          acc["marca"] = key;
        } else if (key !== "proveedor") {
          acc[key.replace(/_/g, " ")] = key;
        }
      }
      return acc;
    }, {});
  }, [data]);

  // Lista de columnas disponibles
  const columns = useMemo(() => Object.keys(columnMapping), [columnMapping]);

  // Efecto para aplicar filtros
  useEffect(() => {
    const filtered = data.filter((row) =>
      Object.entries(filters).every(([key, value]) => {
        const originalKey = columnMapping[key];
        let cellValue = row[originalKey];

        if (
          COLUMN_TYPES.NUMERIC.includes(key.toLowerCase()) ||
          COLUMN_TYPES.CURRENCY.includes(key.toLowerCase())
        ) {
          if (!isNumeric(value)) return true;
          cellValue = isNumeric(cellValue) ? parseFloat(cellValue) : 0;
          const filterValue = parseFloat(value);
          return cellValue === filterValue;
        }

        return cellValue.toString().toLowerCase().includes(value.toLowerCase());
      })
    );
    setFilteredData(filtered);
    setShowFilters(Object.keys(filters).length > 0);
  }, [filters, data, columnMapping]);

  // Función para manejar cambios en filtros
  const handleFilterChange = (column, value) => {
    setFilters((prev) => {
      const newFilters = { ...prev, [column]: value };
      setShowFilters(Object.values(newFilters).some((filter) => filter !== ""));
      return newFilters;
    });
  };

  // Función para limpiar un filtro específico
  const clearFilter = (column) => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      delete newFilters[column];
      setShowFilters(Object.values(newFilters).some((filter) => filter !== ""));
      return newFilters;
    });
    return true; // Retorna true para indicar que el filtro fue limpiado
  };

  // Función para limpiar todos los filtros
  const clearAllFilters = () => {
    setFilters({});
    setShowFilters(false);
    return true; // Retorna true para indicar que los filtros fueron limpiados
  };

  // Función para manejar el ordenamiento
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Efecto para aplicar ordenamiento
  useEffect(() => {
    if (sortConfig.key !== null) {
      const sortedData = [...filteredData].sort((a, b) => {
        const originalKey = columnMapping[sortConfig.key];
        if (a[originalKey] < b[originalKey]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[originalKey] > b[originalKey]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
      setFilteredData(sortedData);
    }
  }, [sortConfig, filteredData, columnMapping]);

  return {
    // Estados
    data,
    filteredData,
    loading,
    error,
    filters,
    showFilters,
    sortConfig,
    columnMapping,
    columns,

    // Funciones
    handleFilterChange,
    clearFilter,
    clearAllFilters,
    requestSort
  };
};

export default useDecantsData;
