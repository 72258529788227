/**
 * Anima un elemento hacia el carrito
 * @param {Event} event - Evento que dispara la animación
 */
export const flyToCart = (event) => {
    const cartIcon = document.querySelector(".cart-button");
    const imgToFly = event.target.closest("td").querySelector("img");
  
    if (!imgToFly || !cartIcon) return;
  
    const imgClone = imgToFly.cloneNode(true);
    const rect = imgToFly.getBoundingClientRect();
  
    // Configurar estilos iniciales
    Object.assign(imgClone.style, {
      position: "absolute",
      top: `${rect.top}px`,
      left: `${rect.left}px`,
      width: `${rect.width}px`,
      height: `${rect.height}px`,
      zIndex: "1000",
      transition: "all 0.8s ease-in-out"
    });
  
    document.body.appendChild(imgClone);
  
    const cartRect = cartIcon.getBoundingClientRect();
  
    // Animar
    requestAnimationFrame(() => {
      imgClone.style.transform = `translate(${cartRect.left - rect.left}px, ${
        cartRect.top - rect.top
      }px) scale(0.3)`;
    });
  
    // Limpiar
    setTimeout(() => {
      imgClone.remove();
    }, 800);
  };