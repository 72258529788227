import React, { useRef, useState, useEffect } from "react";
import Modal from "react-modal";
import CloseIcon from "../assets/boton-cerrar.png";
import DeleteIcon from "../assets/boton-eliminar.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { ToastContainer, toast } from "react-toastify"; // Importa Toastify
import "react-toastify/dist/ReactToastify.css"; // Estilos de Toastify

const CartModal = ({ isOpen, closeCartModal, cart, removeFromCart }) => {
  const formatCurrency = (value) => {
    if (value == null || isNaN(value)) return "No Disponible";
    return `$${parseInt(value).toLocaleString("es-CL")}`;
  };

  const parseCurrency = (value) => {
    if (typeof value === "string") {
      return parseFloat(value.replace(/[$.]/g, "").replace(/,/g, ""));
    }
    return value;
  };

  const sortedCart = [...cart].sort((a, b) => a.id - b.id);
  const totalItems = sortedCart.length;
  const totalValue = sortedCart.reduce(
    (sum, item) => sum + parseCurrency(item.valor || 0),
    0
  );

  const modalRef = useRef(null);
  // esto es para evitar el warning
  // eslint-disable-next-line 
  const [isExporting, setIsExporting] = useState(false);
  const [isInstagramBrowser, setIsInstagramBrowser] = useState(false);

  // Detectar si el navegador es el de Instagram
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (userAgent.includes("Instagram")) {
      setIsInstagramBrowser(true);
    }
  }, []);

  // Función para exportar como imagen
  const exportToImage = () => {
    if (modalRef.current) {
      setIsExporting(true);
      // Cambiar el estilo del contenedor para que no tenga scroll y ajuste al contenido
      const originalStyle = modalRef.current.style.overflowY;
      const originalmaxHeight = modalRef.current.style.maxHeight;
      modalRef.current.style.overflowY = "visible";
      modalRef.current.style.maxHeight = "none";

      html2canvas(modalRef.current, {
        scale: 2,
        useCORS: true,
        logging: true,
        allowTaint: true,
        width: modalRef.current.scrollWidth, // Ajustar el ancho del canvas
        height: modalRef.current.scrollHeight, // Ajustar la altura del canvas
      })
        .then((canvas) => {
          const link = document.createElement("a");
          link.download = "cotizacion.png";
          link.href = canvas.toDataURL("image/png");
          link.click();
        })
        .catch((error) => {
          console.error("Error al generar la imagen:", error);
        })
        .finally(() => {
          modalRef.current.style.overflowY = originalStyle;
          modalRef.current.style.maxHeight = originalmaxHeight; //'600px';
          setIsExporting(false);
        });
    }
  };

  // Función para exportar como PDF con paginación y pie de página con número de página
  const exportToPDF = () => {
    if (modalRef.current) {
      setIsExporting(true);
      const originalStyle = modalRef.current.style.overflowY;
      const originalmaxHeight = modalRef.current.style.maxHeight;
      modalRef.current.style.overflowY = "visible";
      modalRef.current.style.maxHeight = "none";

      html2canvas(modalRef.current, {
        scale: 2, // Aumentamos el scale para mejor calidad
        useCORS: true,
        logging: true,
        allowTaint: true,
        width: modalRef.current.scrollWidth,
        height: modalRef.current.scrollHeight,
      })
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");

          const imgWidth = 150; // Ancho de la imagen en mm
          const pageHeight = 297; // Alto de la página A4 en mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width; // Escalado proporcional
          let heightLeft = imgHeight;

          let position = 0;
          let pageNumber = 1; // Inicializamos el número de página

          // Función para agregar pie de página con el número de página
          const addFooter = (pdf, pageNumber) => {
            pdf.setFontSize(10);
            pdf.text(
              `Página ${pageNumber}`,
              pdf.internal.pageSize.getWidth() - 10,
              pdf.internal.pageSize.getHeight() - 10,
              { align: "right" }
            );
          };

          // Calcular la posición x para centrar la imagen
          const xPosition = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;

          // Agregamos la primera imagen
          pdf.addImage(
            imgData,
            "PNG",
            xPosition,
            position,
            imgWidth,
            imgHeight
          );
          addFooter(pdf, pageNumber); // Agregamos el pie de página en la primera página
          heightLeft -= pageHeight;

          // Si queda contenido por mostrar, se crean más páginas
          while (heightLeft > 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pageNumber++; // Incrementamos el número de página
            pdf.addImage(
              imgData,
              "PNG",
              xPosition,
              position,
              imgWidth,
              imgHeight
            ); // Usamos la posición centrada
            addFooter(pdf, pageNumber); // Agregamos el pie de página en cada nueva página
            heightLeft -= pageHeight;
          }

          pdf.save("cotizacion.pdf");
        })
        .catch((error) => {
          console.error("Error al generar el PDF:", error);
        })
        .finally(() => {
          // Restaurar el estilo original
          modalRef.current.style.overflowY = originalStyle;
          modalRef.current.style.maxHeight = originalmaxHeight; //'600px'; // Restaurar el tamaño original
          setIsExporting(false);
        });
    }
  };

  // Función para enviar el mensaje por Instagram
  const sendMessage = async () => {
    const messageLines = sortedCart.map(
      (item) =>
        `■ ${item.marca} - ${item.nombre} ${item.conc} - ${
          item.titulo
        }: ${formatCurrency(item.valor)}`
    );

    const message = `Hola! Hice esta cotización en CasaValentine.cl:\n\n${messageLines.join(
      "\n"
    )}\n\nValor Total: ${formatCurrency(totalValue)}`;

    // Campo oculto para texto
    const textArea = document.createElement("textarea");
    textArea.value = message;
    textArea.style.position = "fixed"; // Evitar que aparezca en pantalla
    textArea.style.opacity = 0; // Hacerlo invisible
    document.body.appendChild(textArea);

    try {
      // Intentar copiar automáticamente
      await navigator.clipboard.writeText(message);

      // Notificación personalizada
      toast.success("El mensaje ha sido copiado al portapapeles.", {
        position: "top-center",
        autoClose: 3000,
        theme: "light",
      });
    } catch (err) {
      // Copia manual para dispositivos donde no funciona el copy automático
      textArea.select();
      textArea.setSelectionRange(0, 99999); // Para móviles

      try {
        document.execCommand("copy");
        toast.success("Copiado al portapapeles.", {
          position: "top-center",
          autoClose: 3000,
          theme: "light",
        });
      } catch (err) {
        toast.error("No se pudo copiar automáticamente.", {
          position: "top-center",
          autoClose: 5000,
          theme: "light",
        });
      }
    }
    document.body.removeChild(textArea); // Elimina el textarea
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeCartModal}
      className="cart-customModal"
      overlayClassName="cart-customModalOverlay"
    >
      <button onClick={closeCartModal} className="cart-close-button">
        <img src={CloseIcon} alt="Cerrar" />
      </button>
      <div className="cart-customModalContent">
        <div ref={modalRef} className="cart-modal-content">
          <h2>CASAVALENTINE Cotización</h2>
          {sortedCart.length === 0 ? (
            <div>No hay productos en el carrito.</div>
          ) : (
            <>
              <div className="cart-table-container">
                <table id="tblcarrito">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Producto</th>
                      <th>Imagen</th>
                      <th>ML</th>
                      <th>Valor</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedCart.map((item, index) => (
                      <tr key={index}>
                        <td>{item.id}</td>
                        <td>
                          {item.marca} - {item.nombre} - {item.conc}
                        </td>
                        <td>
                          <img
                            src={item.imagen}
                            alt="Descripción de la imagen"
                            className="cart-product-image"
                          />
                        </td>
                        <td>{item.titulo}</td>
                        <td>{formatCurrency(item.valor)}</td>
                        <td>
                          <span
                            onClick={() => removeFromCart(index)}
                            className="cart-delete-button"
                          >
                            <img src={DeleteIcon} alt="Eliminar" />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="cart-totals">
                <p>Total de Productos: {totalItems}</p>
                <p>Valor Total: {formatCurrency(totalValue)}</p>
              </div>
            </>
          )}
        </div>
        {/* Mostrar mensaje si el total es inferior a $15,000, y el botón si es mayor o igual */}
        <div className="cart-export-buttons">
          {totalValue < 15000 ? (
            <p style={{ color: "red", fontWeight: "bold" }}>
              Debes completar $15.000 para realizar un pedido.
            </p>
          ) : (
            <>
              {/* Deshabilitar los botones de exportar si se detecta el navegador de Instagram */}
              <button
                onClick={exportToImage}
                className="cart-export-button"
                disabled={isInstagramBrowser}
              >
                Exportar como Imagen
              </button>
              <button
                onClick={exportToPDF}
                className="cart-export-button"
                disabled={isInstagramBrowser}
              >
                Exportar como PDF
              </button>
              <button onClick={sendMessage} className="cart-export-button">
                Copiar al portapapeles
              </button>
            </>
          )}
        </div>
      </div>
      <ToastContainer /> {/* Contenedor para las notificaciones de Toastify */}
    </Modal>
  );
};

export default CartModal;
